var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    [
      _c(
        "v-col",
        { attrs: { cols: "12" } },
        [
          _c(
            "v-container",
            { staticStyle: { "max-width": "1600px" }, attrs: { fluid: "" } },
            [
              _c(
                "v-expansion-panels",
                [
                  _c(
                    "v-expansion-panel",
                    [
                      _c("v-expansion-panel-header", [
                        _vm._v(" Search "),
                        _c("span", { staticStyle: { "padding-left": "2px" } }, [
                          _vm._v("(" + _vm._s(_vm.totalRecord) + ")"),
                        ]),
                      ]),
                      _c(
                        "v-expansion-panel-content",
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "3" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "Load Number",
                                      type: "text",
                                    },
                                    on: {
                                      keydown: function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        )
                                          return null
                                        return _vm.searchAllPayableLoads()
                                      },
                                    },
                                    model: {
                                      value: _vm.searchParam.loadNumber,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.searchParam,
                                          "loadNumber",
                                          $$v
                                        )
                                      },
                                      expression: "searchParam.loadNumber",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "3" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "Customer Ref. Id",
                                      type: "text",
                                    },
                                    on: {
                                      keydown: function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        )
                                          return null
                                        return _vm.searchAllPayableLoads()
                                      },
                                    },
                                    model: {
                                      value: _vm.searchParam.customerRefId,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.searchParam,
                                          "customerRefId",
                                          $$v
                                        )
                                      },
                                      expression: "searchParam.customerRefId",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "3" } },
                                [
                                  _c("carrier-auto-complete", {
                                    staticClass: "pt-3",
                                    attrs: { "carrier-text": "Carrier" },
                                    on: { event: _vm.selectedCarrier },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "3" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "Invoice Number",
                                      type: "text",
                                    },
                                    on: {
                                      keydown: function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        )
                                          return null
                                        return _vm.searchAllPayableLoads()
                                      },
                                    },
                                    model: {
                                      value: _vm.searchParam.invoiceNumber,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.searchParam,
                                          "invoiceNumber",
                                          $$v
                                        )
                                      },
                                      expression: "searchParam.invoiceNumber",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "3" } },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "blue white--text dark-3",
                                      attrs: { small: "" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.searchAllPayableLoads()
                                        },
                                      },
                                    },
                                    [
                                      _c("v-icon", { attrs: { small: "" } }, [
                                        _vm._v(" mdi-search-web "),
                                      ]),
                                      _vm._v(" Filter "),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "ml-1",
                                      attrs: { small: "" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.resetSearch()
                                        },
                                      },
                                    },
                                    [_vm._v(" Reset ")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-col",
        { attrs: { cols: "12" } },
        [
          _c(
            "v-container",
            { staticClass: "pt-0 data-container", attrs: { fluid: "" } },
            [
              _c("v-data-table", {
                staticClass: "elevation-2 data-table",
                attrs: {
                  headers: _vm.headers,
                  items: _vm.payablesLoads,
                  "items-per-page": _vm.pageSize,
                  loading: _vm.loadingIcon,
                  "calculate-widths": "",
                  "fixed-header": "",
                  "hide-default-footer": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "item.loadNumber",
                    fn: function ({ item }) {
                      return [
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              to: {
                                name: "Load Detail",
                                params: { id: item.loadNumber },
                              },
                              small: "",
                              target: "_blank",
                              text: "",
                            },
                          },
                          [_vm._v(" " + _vm._s(item.loadNumber) + " ")]
                        ),
                      ]
                    },
                  },
                  {
                    key: "item.customer",
                    fn: function ({ item }) {
                      return [
                        _c(
                          "router-link",
                          {
                            staticClass: "name-link",
                            attrs: {
                              to: {
                                name: "Customer Detail",
                                params: { id: item.customer.id },
                              },
                              target: "_blank",
                            },
                          },
                          [_vm._v(" " + _vm._s(item.customer.name) + " ")]
                        ),
                      ]
                    },
                  },
                  {
                    key: "item.carrierName",
                    fn: function ({ item }) {
                      return [
                        _c(
                          "router-link",
                          {
                            staticClass: "name-link",
                            attrs: {
                              to: {
                                name: "Carrier Detail",
                                params: {
                                  id: item.carrier.id,
                                  title:
                                    item.carrier.name +
                                    " - " +
                                    item.carrier.mc_number,
                                },
                              },
                              target: "_blank",
                            },
                          },
                          [
                            _c("span", { staticStyle: { color: "#464646" } }, [
                              _vm._v(_vm._s(item.carrierName)),
                            ]),
                          ]
                        ),
                      ]
                    },
                  },
                  {
                    key: "item.paymentMethod",
                    fn: function ({ item }) {
                      return [
                        item.carrier.primary_payment_method
                          ? _c(
                              "span",
                              [
                                _c("p", { staticClass: "mb-0" }, [
                                  _c("b", [
                                    _vm._v(
                                      _vm._s(
                                        item.carrier.primary_payment_method
                                          .payment_method
                                      )
                                    ),
                                  ]),
                                ]),
                                _c("p", { staticClass: "mb-0" }, [
                                  _vm._v(
                                    _vm._s(
                                      item.carrier.primary_payment_method.name
                                    )
                                  ),
                                ]),
                                item.carrier.primary_payment_method
                                  .payment_method === "ACH"
                                  ? [
                                      _c("p", { staticClass: "mb-0" }, [
                                        _vm._v(
                                          "Account Number: " +
                                            _vm._s(
                                              item.carrier
                                                .primary_payment_method
                                                .account_number
                                            )
                                        ),
                                      ]),
                                      _c("p", { staticClass: "mb-0" }, [
                                        _vm._v(
                                          "Routing Number: " +
                                            _vm._s(
                                              item.carrier
                                                .primary_payment_method
                                                .routing_number
                                            )
                                        ),
                                      ]),
                                    ]
                                  : [
                                      _c("p", { staticClass: "mb-0" }, [
                                        _vm._v(
                                          _vm._s(
                                            item.carrier.primary_payment_method
                                              .businessName
                                          )
                                        ),
                                      ]),
                                      _c("p", { staticClass: "mb-0" }, [
                                        _vm._v(
                                          _vm._s(
                                            item.carrier.primary_payment_method
                                              .address_line_1
                                          )
                                        ),
                                      ]),
                                      _c("p", { staticClass: "mb-0" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.formatLocation(
                                              item.carrier
                                                .primary_payment_method.city,
                                              item.carrier
                                                .primary_payment_method.state
                                            )
                                          ) +
                                            " " +
                                            _vm._s(
                                              item.carrier
                                                .primary_payment_method
                                                .postal_code
                                            )
                                        ),
                                      ]),
                                    ],
                              ],
                              2
                            )
                          : _c("span", [
                              _c("p", { staticClass: "mb-0" }, [
                                _vm._v("Not configured"),
                              ]),
                            ]),
                      ]
                    },
                  },
                  {
                    key: "item.paymentType",
                    fn: function ({ item }) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              item.carrier.quick_pay ? "Quick Pay" : "Net 30"
                            ) +
                            " "
                        ),
                      ]
                    },
                  },
                  {
                    key: "item.email",
                    fn: function ({ item }) {
                      return [
                        item.carrier.primary_payment_method &&
                        item.carrier.primary_payment_method.payment_method ===
                          "FACTORING"
                          ? [
                              item.carrier.primary_payment_method.email
                                ? _c(
                                    "p",
                                    { staticClass: "mb-0" },
                                    [
                                      _c("v-icon", { attrs: { small: "" } }, [
                                        _vm._v(" mdi-email "),
                                      ]),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            item.carrier.primary_payment_method
                                              .email
                                          )
                                        ),
                                      ]),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              item.carrier.primary_payment_method.email
                                ? _c(
                                    "p",
                                    { staticClass: "mb-0" },
                                    [
                                      _c("v-icon", { attrs: { small: "" } }, [
                                        _vm._v(" mdi-phone "),
                                      ]),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            item.carrier.primary_payment_method
                                              .phone
                                          )
                                        ),
                                      ]),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]
                          : [
                              item.email
                                ? _c(
                                    "p",
                                    { staticClass: "mb-0" },
                                    [
                                      _c("v-icon", { attrs: { small: "" } }, [
                                        _vm._v(" mdi-email "),
                                      ]),
                                      _c("span", [_vm._v(_vm._s(item.email))]),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              item.email
                                ? _c(
                                    "p",
                                    { staticClass: "mb-0" },
                                    [
                                      _c("v-icon", { attrs: { small: "" } }, [
                                        _vm._v(" mdi-phone "),
                                      ]),
                                      _c("span", [_vm._v(_vm._s(item.phone))]),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                      ]
                    },
                  },
                  {
                    key: "item.loadMargin",
                    fn: function ({ item }) {
                      return [
                        _c("p", { staticClass: "mb-0" }, [
                          _c("b", [_vm._v("Carrier Price:")]),
                          item.carrierPrice
                            ? _c(
                                "span",
                                { staticClass: "blue--text text--darken" },
                                [_vm._v(" $" + _vm._s(item.carrierPrice))]
                              )
                            : _vm._e(),
                        ]),
                        _c("p", { staticClass: "mb-0" }, [
                          _c("b", [_vm._v("Accessorial:")]),
                          item.loadMargin
                            ? _c(
                                "span",
                                { staticClass: "blue--text text--darken" },
                                [
                                  _vm._v(
                                    " $" +
                                      _vm._s(
                                        item.loadMargin
                                          .carrier_total_accessorials_paid_to_carrier
                                      ) +
                                      " "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]),
                        _c("p", { staticClass: "mb-0" }, [
                          _c("b", [_vm._v("Total:")]),
                          item.loadMargin
                            ? _c(
                                "span",
                                { staticClass: "blue--text text--darken" },
                                [
                                  _vm._v(
                                    " $" +
                                      _vm._s(
                                        item.loadMargin
                                          .carrier_total_billable_price
                                      ) +
                                      " "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]),
                      ]
                    },
                  },
                  {
                    key: "item.invoicedDate",
                    fn: function ({ item }) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(item.invoicedDate || "N/A")),
                        ]),
                      ]
                    },
                  },
                  {
                    key: "item.uploadFile",
                    fn: function ({ item }) {
                      return [
                        item.uploadFile
                          ? _c(
                              "a",
                              {
                                staticClass: "name-link",
                                attrs: {
                                  href: item.uploadFile,
                                  target: "_blank",
                                },
                              },
                              [
                                _c("v-icon", { attrs: { small: "" } }, [
                                  _vm._v("mdi-file-pdf-outline"),
                                ]),
                              ],
                              1
                            )
                          : _c("div", [_vm._v("No invoice file")]),
                      ]
                    },
                  },
                  {
                    key: "item.pay",
                    fn: function ({ item }) {
                      return [
                        _c(
                          "v-tooltip",
                          {
                            attrs: {
                              bottom: "",
                              disabled:
                                item.carrier.has_primary_payment_method ||
                                item.carrier.factoring_company,
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function ({ on, attrs }) {
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              staticClass:
                                                "font-weight-bold blue darken-1 ml-2",
                                              attrs: {
                                                small: "",
                                                disabled:
                                                  !item.carrier
                                                    .has_primary_payment_method &&
                                                  !item.carrier
                                                    .factoring_company,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.confirmChangeLoadStatus(
                                                    item.loadNumber,
                                                    item.loadMargin
                                                      .carrier_total_billable_price,
                                                    item.carrier
                                                  )
                                                },
                                              },
                                            },
                                            "v-btn",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [_vm._v(" Pay ")]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          },
                          [
                            _c("span", [
                              _vm._v(
                                "Carrier has no configured payment method"
                              ),
                            ]),
                          ]
                        ),
                      ]
                    },
                  },
                  {
                    key: "footer",
                    fn: function () {
                      return [
                        _c("v-page", {
                          staticClass: "pt-3 pr-3 pb-3",
                          attrs: {
                            "page-size-menu": [10, 20, 50, 100],
                            "total-row": _vm.totalRecord,
                          },
                          on: { "page-change": _vm.readDataFromAPI },
                        }),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "290" },
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "text-h5" }, [
                _vm._v(" Submit payment "),
              ]),
              _c("v-card-text", [
                _c("p", [_vm._v("Please confirm payment amount:")]),
                _c("p", { staticClass: "text-center mb-0" }, [
                  _c("b", [_vm._v("$" + _vm._s(_vm.payAmount))]),
                ]),
              ]),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "grey darken-1", text: "" },
                      on: {
                        click: function ($event) {
                          _vm.dialog = false
                        },
                      },
                    },
                    [_vm._v(" Cancel ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "orange darken-1", text: "" },
                      on: {
                        click: function ($event) {
                          return _vm.loadAmountPaid()
                        },
                      },
                    },
                    [_vm._v(" Pay ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("carrier-a-r-edit", { on: { event: _vm.operationEvent } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }